<template>
  <div class="page">
    <Navbar title="用户注册" type="SMP" />
    <img
      class="login-top"
      :src="require('../../../assets/images/seller/login-top.jpg')"
    />
    <van-form @submit="register" class="form">
      <van-cell-group>
        <van-field
          v-model="state.phone"
          label="手机号"
          type="tel"
          placeholder="请输入手机号"
          :rules="[{ required: true, message: '请填写手机号码' }]"
        ></van-field>
        <van-field
          v-model="state.password"
          label="登录密码"
          type="pwd"
          placeholder="请输入登录密码"
          :rules="[{ required: true, message: '请填写登录密码' }]"
        ></van-field>
        <van-field
          v-model="state.pwdConfirm"
          label="确认密码"
          type="pwd"
          placeholder="请重新输入确认密码"
          :rules="[{ required: true, message: '请重新输入确认密码' }]"
        >
        </van-field>
        <van-field
          v-model="state.vcode"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
          :rules="[{ required: true, message: '请填写短信验证码' }]"
        >
          <template #button>
            <van-button
              size="mini"
              v-if="sendBtnTime > 0"
              :disabled="true"
              :color="COLOR"
              @click="sendValidate()"
              >发送验证码({{ sendBtnTime }})</van-button
            >
            <van-button
              size="mini"
              v-if="sendBtnTime <= 0"
              :color="COLOR_S1"
              @click="sendValidate()"
              >发送验证码</van-button
            >
          </template>
        </van-field>
        <van-row>
          <van-col offset="8" span="8" class="subbtn">
            <van-button
              block
              type="primary"
              icon="success"
              :color="COLOR_S1"
              size="small"
              native-type="submit"
            >
              立即绑定
            </van-button></van-col
          >
        </van-row>
      </van-cell-group>
    </van-form>
    <van-row>
      <van-col span="24"
        >已有平台账号？<a class="color-m" @click="login"
          >点击此处立即登录</a
        ></van-col
      >
    </van-row>
    <van-popup :show="loadingShow" style="background-color: transparent"
      ><van-loading size="24px" :color="COLOR" vertical>加载中...</van-loading>
    </van-popup>
    <Share ref="share" />
  </div>
</template>
<script>
import { reactive } from 'vue'
import { Form, Field, CellGroup } from 'vant'
import Navbar from '../../module/common/Navbar.vue'
import Share from '../../module/common/Share.vue'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup
  },
  setup () {
    const state = reactive({
      phone: '',
      password: '',
      pwdConfirm: '',
      vcode: ''
    })
    return {
      state
    }
  },
  data () {
    return {
      operatorCode: '',
      sellerCode: '',
      refereeCode: '',
      sendBtnTime: 0,
      loadingShow: false,
      serialCode: '1',
      validateState: true,
      upper: '1'
    }
  },
  mounted () {
    window.sessionStorage.setItem(this.SESSION_APP, 'NAP_SEL')
    this.operatorCode = window.sessionStorage.getItem(this.SESSION_OPERATOR)
    this.sellerCode = window.sessionStorage.getItem(this.SESSION_SELLER)
    this.refereeCode = window.sessionStorage.getItem(this.SESSION_REFEREE)
    var uc = window.sessionStorage.getItem(this.SESSION_REFEREE)
    var sc = window.sessionStorage.getItem(this.SESSION_SERIAL)
    if (uc !== null && uc !== undefined && uc !== '') {
      this.upper = uc
    }
    if (sc !== null && sc !== undefined && sc !== '') {
      this.serialCode = sc
    }
    this.$refs.share.default('main')
  },
  methods: {
    async register () {
      if (this.validateState) {
        this.loadingShow = true
        var pd = { operatorCode: this.operatorCode, sellerCode: this.sellerCode, refereeCode: this.refereeCode, phone: this.state.phone, password: this.state.password, vcode: this.state.vcode }
        const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/register', this.$qs.stringify(pd))
        this.loadingShow = false
        if (res.status === '200') {
          this.$dialog.alert({
            title: '提示信息',
            message: '注册账号成功'
          }).then(() => {
            this.$router.push({ path: '/seller/login' })
          })
        } else {
          this.$dialog.alert({
            title: '提示信息',
            message: res.msg
          }).then(() => {
            // on close
          })
        }
      } else {
        this.$dialog.alert({
          title: '验证码提示',
          message: '请点击发送手机验证码'
        }).then(() => {
          // on close
        })
      }
    },
    login () {
      this.$router.push({ path: '/seller/login' })
    },
    async sendValidate () {
      var pd = { phone: this.state.phone }
      var that = this
      const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/sendValidate', this.$qs.stringify(pd))
      if (res.status === '200') {
        that.validateState = true
        that.sendBtnTime = 60
        that.countdown()
      }
    },
    countdown () {
      var that = this
      setTimeout(function () {
        that.sendBtnTime--
        if (that.sendBtnTime > 0) {
          that.countdown()
        }
      }, 1000)
    }
  }
}
</script>
<style lang="less" scoped>
.login-top {
  width: 100%;
}
.form {
  padding: 10px 5px 10px 0px;
}
.title {
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  padding-left: 10px;
  color: #999;
  height: 30px;
  line-height: 30px;
}
.subbtn {
  margin: 20px auto;
}
</style>
